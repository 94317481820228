import { type Sort } from 'mongodb'
// import { type Feature } from 'geojson'
import React, { useEffect, useMemo, useState } from 'react'
import { ValidatedForm, useControlField } from 'remix-validated-form'
import { useAdminCompaniesQuery, useGetParcels, useListingsQuery } from '~/api'
import {
  aOrganization,
  aUser,
  getSortFromFilters,
  useAtomValue,
  type MapFiltersType,
} from '~/atoms'
import { FieldContainer, LoadingCircle } from '~/components'
import { PdfClientBrowser, PdfClientDownload } from '~/components/pdfs'
import { Button, Dialog, Input } from '~/components/ui'
import { useDebounce } from '~/hooks'
import {
  getDefaultValues,
  getFindFromReport,
  reportPdfValidator,
  type Listing,
} from '~/models'
import PdfFormContent from './FormContent'
import ReportPdfSwitch from './ReportPdfSwitch'

function PdfTitle({
  formId,
  initialTitle,
}: {
  formId: string
  initialTitle: string
}) {
  const [titleValue, setTitleValue] = useState<string>(initialTitle)
  const debouncedTitleValue = useDebounce(titleValue, 1000)
  const [_, setTitle] = useControlField<string>('title', formId)

  useEffect(() => {
    setTitle(debouncedTitleValue)
  }, [debouncedTitleValue, setTitle])

  return (
    <FieldContainer label="Title" id="title" className="col-span-12">
      <Input
        id="title"
        name="title"
        value={titleValue}
        onChange={(e) => setTitleValue(e.target.value)}
      />
    </FieldContainer>
  )
}

export default function CreatePdfDialog({
  children,
  listingIds,
  filters,
  initialTitle = '',
  singleListing = false,
}: {
  children: React.ReactNode
  listingIds: string[]
  filters?: MapFiltersType
  initialTitle?: string
  singleListing?: boolean
}) {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)

  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const { companies } = useAdminCompaniesQuery(
    ['pdf-user-company', 'disable-cache'],
    { _id: user?.company?._id },
    { enabled: !!user?.company }
  )
  const company = useMemo(() => {
    if (user && companies.length > 0) {
      return companies[0]
    }
  }, [companies, user])

  const find = useMemo(() => {
    return getFindFromReport({
      includeIds: listingIds,
      // filters: locationState.filters,
      // location: getStateLocation,
    })
  }, [listingIds])

  const sort: Sort | undefined = useMemo(() => {
    return filters ? getSortFromFilters(filters) : {}
  }, [filters])

  const { listings, isLoading } = useListingsQuery({
    key: ['create-pdf-listings', 'disable-cache', ...listingIds],
    find,
    sort,
    limit: 50,
    refetchOnMount: false,
  })

  const sortedListings = useMemo(() => {
    if (listingIds) {
      return (
        (listingIds
          .filter((_id) => listings.find((listing) => listing._id === _id))
          .map((_id) => {
            return listings.find((listing) => listing._id === _id)
          })
          .filter((v) => v) as Listing[]) || []
      )
    }
    return listings
  }, [listingIds, listings])

  const formId = 'create-pdf'
  const [title] = useControlField<string>('title', formId)
  const [type] = useControlField<string>('options.type', formId)
  const [preparedFor] = useControlField<string>('options.preparedFor', formId)
  const [notes] = useControlField<string>('options.notes', formId)
  const [showTitlePage] = useControlField<boolean>(
    'options.showTitlePage',
    formId
  )
  const [showContents] = useControlField<boolean>(
    'options.showContents',
    formId
  )
  const [showBrokerProfile] = useControlField<boolean>(
    'options.showBrokerProfile',
    formId
  )
  const [showMapPage] = useControlField<boolean>('options.showMapPage', formId)
  const [showLeaseSpaces] = useControlField<boolean>(
    'options.showLeaseSpaces',
    formId
  )
  const [showBrokers] = useControlField<boolean>('options.showBrokers', formId)
  const [showImages] = useControlField<boolean>('options.showImages', formId)
  const [showParcels] = useControlField<boolean>('options.showParcels', formId)

  const { parcels } = useGetParcels({
    enabled: showParcels,
    parcelApns: listings
      .filter((l) => l.property.parcelApn)
      .map((l) => l.property.parcelApn!),
  })

  const defaultValues = useMemo(() => {
    return getDefaultValues(null, {
      title: initialTitle,
      options: {
        type: 'flyers',
        showTitlePage: false,
        // showContents: sortedListings.length > 20 ? false : true,
        showContents: false,
        showBrokerProfile: true,
        // showBrokerProfile:
        //   user?.role === UserRole.broker ||
        //   user?.role === UserRole.managingBroker
        //     ? true
        //     : false,
        // showMapPage: sortedListings.length > 20 ? false : true,
        showMapPage: false,
        showBrokers: false,
        showImages: false,
        showParcels: false,
      },
    })
  }, [initialTitle])

  if (!user) {
    return null
  }

  return (
    <Dialog modal={false}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content className="flex h-[calc(100vh-8rem)] w-[calc(100vw-8rem)] max-w-none flex-col">
        <Dialog.Header>
          <Dialog.Title className="">Download PDF</Dialog.Title>
        </Dialog.Header>

        <div className="grid flex-1 grid-cols-12 gap-8 overflow-y-auto overflow-x-hidden">
          <div className="col-span-12 px-1 lg:col-span-4 lg:overflow-auto">
            <ValidatedForm
              id={formId}
              validator={reportPdfValidator}
              defaultValues={defaultValues as any}
              // method="post"
              // action={`/admin/reports/${report.slug}/pdf`}
            >
              <div className="grid grid-cols-12 gap-6 pt-4">
                <PdfTitle formId={formId} initialTitle={initialTitle} />
                <p className="col-span-12 -mb-4 text-lg font-medium">
                  PDF Options
                </p>
                <PdfFormContent formId={formId} singleListing={singleListing} />
              </div>
            </ValidatedForm>
          </div>
          <div className="relative col-span-12 h-[500px] lg:col-span-8 lg:h-auto">
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-muted/50 pb-64">
              <LoadingCircle className="h-10 w-10 text-primary" />
              <p className="mt-2">Loading...</p>
            </div>
            {isLoaded && !isLoading && (
              <PdfClientBrowser
                document={
                  <ReportPdfSwitch
                    organization={organization!}
                    title={title}
                    company={company}
                    user={user!}
                    listings={sortedListings}
                    parcels={parcels}
                    pdfOptions={{
                      type,
                      preparedFor,
                      notes,
                      showTitlePage,
                      showContents,
                      showLeaseSpaces,
                      showBrokerProfile,
                      showMapPage,
                      showBrokers,
                      showImages,
                      showParcels,
                    }}
                  />
                }
              />
            )}
          </div>
        </div>

        <Dialog.Footer className="">
          <Dialog.Close asChild>
            <Button variant="ghost">Close</Button>
          </Dialog.Close>
          {isLoaded && (
            <PdfClientDownload
              fileName={title}
              document={
                <ReportPdfSwitch
                  organization={organization!}
                  title={title}
                  company={company}
                  user={user!}
                  listings={sortedListings}
                  parcels={parcels}
                  pdfOptions={{
                    type,
                    preparedFor,
                    notes,
                    showTitlePage,
                    showContents,
                    showBrokerProfile,
                    showMapPage,
                    showLeaseSpaces,
                    showBrokers,
                    showImages,
                    showParcels,
                  }}
                />
              }>
              Download
            </PdfClientDownload>
          )}
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
